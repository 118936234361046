// DialogBox.js
import React from 'react';
import './DialogStore.css'; // Importing the CSS for styling
import { useState, useEffect } from 'react';

const DialogStore = ({ isOpen, onCancel, onConfirm}) => {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    // Ethereum address regex check
    const isValidEthAddress = /^0x[a-fA-F0-9]{64}$/.test(value);
    setIsValid(isValidEthAddress);
  };

  if (!isOpen) return null; // Only render the dialog if isOpen is true

  
  const handleSubmit = () => {
    if (isValid) {
      // Assuming onOption is a method passed via props or defined in the component
      onConfirm(inputValue);
    } else {
      alert("Please enter a valid ETH wallet address.");
    }
  };
  
  return (
    <div className="dialog-overlay">
      <div className="dialog">
        <div className="dialog-content">
          <div className="dialog-text-section">
            <p>1. Send RON to nakamabot.ron ( Rate: 1 RON x 10 tokens )</p>
            <p>2. Claim your Tokens</p>
            <p>!Important: Remember you have to make the transaction from your wallet connected to Nakama.bot</p>
          </div>
          <div className="buttons-section"></div>
            <button onClick={onCancel} >Cancel</button>
            <button className="confirm-button" onClick={handleSubmit} disabled={!isValid}>Claim!</button>
          </div>
      </div>
    </div>
  );
};

export default DialogStore;