import './NakamaPrivacyPolicy.css';

import { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "components/AppContext";


function NakamaPrivacyPolicy() {

    const { global_app, updateGlobalVariables } = useContext(AppContext);

    //   useEffect(() => {
    //     navigate('/app/');
    //   }, [navigate]);

    const navigate = useNavigate();

    useEffect(() => {
        global_app.control.loadingPage = false;
        updateGlobalVariables(global_app)
        console.log(" VAMOS A APAGAR EL LOADING UNA SOLA VEZ!")
    }, []);

    return (

        <div className="NakamaPrivacyPolicy">
            NakamaPrivacyPolicy
        </div>
    );
}

export default NakamaPrivacyPolicy;
